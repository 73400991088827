import React, { useEffect, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    section,
    classicTheme,
    mintTheme,
    container,
    box,
    title,
    subtitle,
    description,
    item,
    itemImage,
    itemName,
    slider,
    bottleImage,
    backgroundImage,
    animation,
} from './gorzka-ingredients.module.scss';

import Slider from '../hoc/slider';

interface IGorzkaIngredientsProps {
    theme: 'classic' | 'mint';
    className?: string;
}

const ingredientOrangeUrl = '../../assets/images/soplica-gorzka/orange.png';
const ingredientPeelUrl = '../../assets/images/soplica-gorzka/peel.png';
const ingredientClovesUrl = '../../assets/images/soplica-gorzka/cloves.png';
const ingredientMintUrl = '../../assets/images/soplica-gorzka/mint.png';
const ingredientBratekUrl = '../../assets/images/soplica-gorzka/bratek.png';
const ingredientBylicaUrl = '../../assets/images/soplica-gorzka/bylica.png';
const ingredientDrapaczUrl = '../../assets/images/soplica-gorzka/drapacz.png';
const ingredientJezowkaUrl = '../../assets/images/soplica-gorzka/jezowka.png';
const ingredientKrwawnikUrl = '../../assets/images/soplica-gorzka/krwawnik.png';
const ingredientMarzankaUrl = '../../assets/images/soplica-gorzka/marzanka.png';
const ingredientMniszekUrl = '../../assets/images/soplica-gorzka/mniszek.png';
const ingredientNawlocUrl = '../../assets/images/soplica-gorzka/nawloc.png';
const ingredientNostrzykUrl = '../../assets/images/soplica-gorzka/nostrzyk.png';
const ingredientPokrzywaUrl = '../../assets/images/soplica-gorzka/pokrzywa.png';
const ingredientPrzytulipaUrl = '../../assets/images/soplica-gorzka/przytulipa.png';
const ingredientSkrzypUrl = '../../assets/images/soplica-gorzka/skrzyp.png';

const classicBottleUrl = '../../assets/images/soplica-gorzka/classic-slider-bottle.png';
const classicLeafUrl = '../../assets/images/soplica-gorzka/classic-slider-bottle-leaf.png';
const classicSplashUrl = '../../assets/images/soplica-gorzka/classic-slider-bottle-splash.png';
const mintBottleUrl = '../../assets/images/soplica-gorzka/mint-slider-bottle.png';
const mintMintUrl = '../../assets/images/soplica-gorzka/mint-slider-bottle-mint.png';
const mintLeafUrl = '../../assets/images/soplica-gorzka/mint-slider-bottle-leaf.png';

const GorzkaIngredients: React.FC<IGorzkaIngredientsProps> = ({ theme, className = '' }) => {
    const { t } = useI18next();
    const sectionRef = useRef<HTMLDivElement>(null);
    const [showAnimation, setShowSliderAnimation] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const sectionOffsetTop =
                (sectionRef &&
                    sectionRef.current &&
                    sectionRef.current.getBoundingClientRect().top) ||
                0;
            const sectionHeight =
                (sectionRef && sectionRef.current && sectionRef.current.clientHeight) || 0;
            const showAnimation = sectionOffsetTop + sectionHeight / 4 < window.innerHeight;

            if (showAnimation) {
                setShowSliderAnimation(true);
            } else {
                setShowSliderAnimation(false);
            }
        });
    }, [sectionRef]);

    return (
        <div
            className={`
            ${section} ${className} 
            ${theme === 'classic' ? classicTheme : mintTheme} 
            ${showAnimation ? animation : ''}
            `}
            ref={sectionRef}
        >
            {theme === 'classic' ? (
                <>
                    <StaticImage className={`${bottleImage}`} src={classicBottleUrl} alt={''} />
                    <StaticImage
                        className={`${backgroundImage} one`}
                        src={classicLeafUrl}
                        alt={''}
                    />
                    <StaticImage
                        className={`${backgroundImage} two`}
                        src={classicSplashUrl}
                        alt={''}
                    />
                </>
            ) : (
                <>
                    <StaticImage className={`${bottleImage}`} src={mintBottleUrl} alt={''} />
                    <StaticImage className={`${backgroundImage} one`} src={mintMintUrl} alt={''} />
                    <StaticImage className={`${backgroundImage} two`} src={mintLeafUrl} alt={''} />
                </>
            )}

            <div className={container}>
                <div className={box}>
                    <p className={title}>{t('gorzka.ingredients.title')}</p>
                    <p className={subtitle}>{t('gorzka.ingredients.subtitle')}</p>
                    {theme === 'mint' ? (
                        <p className={description}>{t('gorzka.ingredients.description.mint')}</p>
                    ) : (
                        <p className={description}>{t('gorzka.ingredients.description')}</p>
                    )}
                    <Slider
                        className={slider}
                        sliderProps={{
                            slidesPerView: 2,
                            breakpoints: { 0: { slidesPerView: 2 } },
                        }}
                        arrow={'chevron'}
                    >
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientOrangeUrl}
                                alt={t('gorzka.ingredient.orange')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.orange')}</p>
                        </div>
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientPeelUrl}
                                alt={t('gorzka.ingredient.peel')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.peel')}</p>
                        </div>
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientClovesUrl}
                                alt={t('gorzka.ingredient.cloves')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.cloves')}</p>
                        </div>
                        {theme === 'mint' && (
                            <div className={item}>
                                <StaticImage
                                    className={itemImage}
                                    src={ingredientMintUrl}
                                    alt={t('gorzka.ingredient.mint')}
                                />
                                <p className={itemName}>{t('gorzka.ingredient.mint')}</p>
                            </div>
                        )}
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientPokrzywaUrl}
                                alt={t('gorzka.ingredient.pokrzywa')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.pokrzywa')}</p>
                        </div>
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientNawlocUrl}
                                alt={t('gorzka.ingredient.nawloc')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.nawloc')}</p>
                        </div>
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientPrzytulipaUrl}
                                alt={t('gorzka.ingredient.przytulipa')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.przytulipa')}</p>
                        </div>
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientBratekUrl}
                                alt={t('gorzka.ingredient.bratek')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.bratek')}</p>
                        </div>
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientJezowkaUrl}
                                alt={t('gorzka.ingredient.jezowka')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.jezowka')}</p>
                        </div>
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientMarzankaUrl}
                                alt={t('gorzka.ingredient.marzanka')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.marzanka')}</p>
                        </div>
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientSkrzypUrl}
                                alt={t('gorzka.ingredient.skrzyp')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.skrzyp')}</p>
                        </div>
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientMniszekUrl}
                                alt={t('gorzka.ingredient.mniszek')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.mniszek')}</p>
                        </div>
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientKrwawnikUrl}
                                alt={t('gorzka.ingredient.krwawnik')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.krwawnik')}</p>
                        </div>
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientDrapaczUrl}
                                alt={t('gorzka.ingredient.drapacz')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.drapacz')}</p>
                        </div>
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientBylicaUrl}
                                alt={t('gorzka.ingredient.bylica')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.bylica')}</p>
                        </div>
                        <div className={item}>
                            <StaticImage
                                className={itemImage}
                                src={ingredientNostrzykUrl}
                                alt={t('gorzka.ingredient.nostrzyk')}
                            />
                            <p className={itemName}>{t('gorzka.ingredient.nostrzyk')}</p>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default GorzkaIngredients;
