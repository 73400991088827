// extracted by mini-css-extract-plugin
export var animation = "gorzka-ingredients-module--animation--81f77";
export var backgroundAnimation = "gorzka-ingredients-module--backgroundAnimation--0ab84";
export var backgroundImage = "gorzka-ingredients-module--background-image--cbeaf";
export var bottleImage = "gorzka-ingredients-module--bottle-image--e505d";
export var box = "gorzka-ingredients-module--box--3a05c";
export var classicTheme = "gorzka-ingredients-module--classicTheme--73808";
export var container = "gorzka-ingredients-module--container--6d95c";
export var description = "gorzka-ingredients-module--description--93504";
export var errorBlink = "gorzka-ingredients-module--error-blink--c1fe9";
export var item = "gorzka-ingredients-module--item--97335";
export var itemImage = "gorzka-ingredients-module--item-image--2c1e4";
export var itemName = "gorzka-ingredients-module--item-name--ee273";
export var mintTheme = "gorzka-ingredients-module--mintTheme--8c691";
export var section = "gorzka-ingredients-module--section--3ee84";
export var slider = "gorzka-ingredients-module--slider--00e0d";
export var subtitle = "gorzka-ingredients-module--subtitle--7eec7";
export var title = "gorzka-ingredients-module--title--bce3d";