// extracted by mini-css-extract-plugin
export var backgroundAnimation = "slider-module--backgroundAnimation--4cc34";
export var buttonNext = "slider-module--button-next--04ca8";
export var buttonPrev = "slider-module--button-prev--de8b0";
export var errorBlink = "slider-module--error-blink--4512a";
export var hidden = "slider-module--hidden--c82f5";
export var listBox = "slider-module--list-box--69ff4";
export var mobileHidden = "slider-module--mobile-hidden--6d1f0";
export var notVisible = "slider-module--notVisible--01d45";
export var sliderBox = "slider-module--slider-box--cf192";
export var sliderFlex = "slider-module--slider-flex--213c1";
export var sliderGrid = "slider-module--slider-grid--cd4ae";
export var sliderLoaded = "slider-module--slider-loaded--642c0";