import React from 'react';

import { progressBar } from './progress-bar.module.scss';

interface IArrowButtonProps {
    className?: string;
}

const ProgressBar = React.forwardRef<HTMLButtonElement, IArrowButtonProps>((props, ref) => {
    const { className = ''} = props;
    return (
        <button
            ref={ref}
            className={`${progressBar} ${className}`}
        />
    );
});

export default ProgressBar;
