import React, { useEffect, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    section,
    classicTheme,
    mintTheme,
    animationOne,
    animationTwo,
    container,
    wrapper,
    icon,
    title,
    description,
    box,
    bottle,
    trait,
    badge,
    slider,
    leaf,
    point,
    bottleAnimation,
} from './gorzka-presentation.module.scss';

import CrownIcon from '../../assets/images/svg/gorzka-theme.svg';

import useMediaQuery from '../../hooks/use-media-query';
import { breakpoints } from '../../config/breakpoints';

import Markdown from '../hoc/markdown';
import Slider from '../hoc/slider';

interface IGorzkaPresentationProps {
    className?: string;
    theme: 'classic' | 'mint';
}

const bottleClassicUrl = '../../assets/images/soplica-gorzka/classic-bottle.png';
const bottleMintUrl = '../../assets/images/soplica-gorzka/mint-bottle.png';
const bottleClassicMobileUrl = '../../assets/images/soplica-gorzka/classic-bottle-mobile.png';
const bottleMintMobileUrl = '../../assets/images/soplica-gorzka/mint-bottle-mobile.png';
const badgeUrl = '../../assets/images/soplica-gorzka/classic-badge.png';
const leafUrl = '../../assets/images/soplica-gorzka/mint-leaf-bottom.png';
const classicPoint1Url = '../../assets/images/soplica-gorzka/classic-point-1.png';
const classicPoint2Url = '../../assets/images/soplica-gorzka/classic-point-2.png';
const classicPoint3Url = '../../assets/images/soplica-gorzka/classic-point-3.png';
const classicPoint4Url = '../../assets/images/soplica-gorzka/classic-point-4.png';
const classicPoint5Url = '../../assets/images/soplica-gorzka/classic-point-5.png';
const classicPoint6Url = '../../assets/images/soplica-gorzka/classic-point-6.png';
const mintPoint1Url = '../../assets/images/soplica-gorzka/mint-point-1.png';
const mintPoint2Url = '../../assets/images/soplica-gorzka/mint-point-2.png';
const mintPoint3Url = '../../assets/images/soplica-gorzka/mint-point-3.png';
const mintPoint4Url = '../../assets/images/soplica-gorzka/mint-point-4.png';
const mintPoint5Url = '../../assets/images/soplica-gorzka/mint-point-5.png';
const mintPoint6Url = '../../assets/images/soplica-gorzka/mint-point-6.png';

const GorzkaPresentation: React.FC<IGorzkaPresentationProps> = ({ className = '', theme }) => {
    const { t } = useI18next();
    const isMobile = useMediaQuery(breakpoints.iPad);
    const sectionRef = useRef<HTMLElement>(null);
    const bottleRef = useRef<HTMLDivElement>(null);
    const [leafAnimation, setLeafAnimation] = useState(0);
    const [showBottleAnimation, setShowBottleAnimation] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const leafScreenY =
                (sectionRef &&
                    sectionRef.current &&
                    sectionRef.current.getBoundingClientRect().y) ||
                0;
            const leafHeight =
                (sectionRef && sectionRef.current && sectionRef.current.clientHeight) || 0;
            const bottleOffsetTop =
                (bottleRef && bottleRef.current && bottleRef.current.getBoundingClientRect().top) ||
                0;

            const showFirstleafAnimation = leafScreenY < window.innerHeight;
            const showSecondLeafAnimation = leafScreenY + leafHeight / 3 < window.innerHeight / 2;
            const showBottleAnimation = bottleOffsetTop + 100 < window.innerHeight;

            if (showSecondLeafAnimation) {
                setLeafAnimation(2);
            } else if (showFirstleafAnimation) {
                setLeafAnimation(1);
            } else {
                setLeafAnimation(0);
            }

            setShowBottleAnimation(showBottleAnimation);
        });
    }, [sectionRef, bottleRef]);

    return (
        <section
            className={`
            ${className} ${section} 
            ${theme === 'mint' ? mintTheme : classicTheme} 
            ${leafAnimation === 1 ? animationOne : ''}
            ${leafAnimation === 2 ? animationTwo : ''}
            `}
            id={theme}
            ref={sectionRef}
        >
            <div className={`${container} ${showBottleAnimation ? bottleAnimation : ''}`}>
                <StaticImage src={badgeUrl} alt={t('gorzka.presentation.alt')} className={badge} />
                <CrownIcon className={icon} />
                <h2 className={title}>
                    {theme === 'mint'
                        ? t('gorzka.presentation.mint.title')
                        : t('gorzka.presentation.classic.title')}
                </h2>
                <p className={description}>
                    {theme === 'mint'
                        ? t('gorzka.presentation.mint.description')
                        : t('gorzka.presentation.classic.description')}
                </p>

                {isMobile ? (
                    <div className={wrapper} ref={bottleRef}>
                        <Slider
                            className={slider}
                            sliderProps={{
                                centeredSlides: true,
                                slidesPerView: 'auto',
                                breakpoints: { 0: { slidesPerView: 'auto' } },
                            }}
                        >
                            <Markdown className={trait}>
                                {t('gorzka.presentation.classic.trait.1')}
                            </Markdown>
                            <Markdown className={trait}>
                                {t('gorzka.presentation.classic.trait.2')}
                            </Markdown>
                            <Markdown className={trait}>
                                {t('gorzka.presentation.classic.trait.3')}
                            </Markdown>
                            <Markdown className={trait}>
                                {t('gorzka.presentation.classic.trait.4')}
                            </Markdown>
                            <Markdown className={trait}>
                                {t('gorzka.presentation.classic.trait.5')}
                            </Markdown>
                            <Markdown className={trait}>
                                {t('gorzka.presentation.classic.trait.6')}
                            </Markdown>
                        </Slider>
                        {theme === 'mint' ? (
                            <StaticImage
                                src={bottleMintMobileUrl}
                                alt={t('gorzka.header.mint')}
                                className={bottle}
                            />
                        ) : (
                            <StaticImage
                                src={bottleClassicMobileUrl}
                                alt={t('gorzka.header.classic')}
                                className={bottle}
                            />
                        )}
                    </div>
                ) : (
                    <div className={box} ref={bottleRef}>
                        {theme === 'mint' ? (
                            <>
                                <StaticImage
                                    src={bottleMintUrl}
                                    alt={t('gorzka.header.mint')}
                                    className={bottle}
                                />
                                <StaticImage
                                    src={mintPoint1Url}
                                    alt={''}
                                    className={`${point} one`}
                                />
                                <StaticImage
                                    src={mintPoint2Url}
                                    alt={''}
                                    className={`${point} two`}
                                />
                                <StaticImage
                                    src={mintPoint3Url}
                                    alt={''}
                                    className={`${point} three`}
                                />
                                <StaticImage
                                    src={mintPoint4Url}
                                    alt={''}
                                    className={`${point} four`}
                                />
                                <StaticImage
                                    src={mintPoint5Url}
                                    alt={''}
                                    className={`${point} five`}
                                />
                                <StaticImage
                                    src={mintPoint6Url}
                                    alt={''}
                                    className={`${point} six`}
                                />
                            </>
                        ) : (
                            <>
                                <StaticImage
                                    src={bottleClassicUrl}
                                    alt={t('gorzka.header.classic')}
                                    className={bottle}
                                />
                                <StaticImage
                                    src={classicPoint1Url}
                                    alt={''}
                                    className={`${point} one`}
                                />
                                <StaticImage
                                    src={classicPoint2Url}
                                    alt={''}
                                    className={`${point} two`}
                                />
                                <StaticImage
                                    src={classicPoint3Url}
                                    alt={''}
                                    className={`${point} three`}
                                />
                                <StaticImage
                                    src={classicPoint4Url}
                                    alt={''}
                                    className={`${point} four`}
                                />
                                <StaticImage
                                    src={classicPoint5Url}
                                    alt={''}
                                    className={`${point} five`}
                                />
                                <StaticImage
                                    src={classicPoint6Url}
                                    alt={''}
                                    className={`${point} six`}
                                />
                            </>
                        )}
                        <Markdown className={`${trait} one`}>
                            {t('gorzka.presentation.classic.trait.1')}
                        </Markdown>
                        <Markdown className={`${trait} two`}>
                            {t('gorzka.presentation.classic.trait.2')}
                        </Markdown>
                        <Markdown className={`${trait} three`}>
                            {t('gorzka.presentation.classic.trait.3')}
                        </Markdown>
                        <Markdown className={`${trait} four`}>
                            {t('gorzka.presentation.classic.trait.4')}
                        </Markdown>
                        <Markdown className={`${trait} five`}>
                            {t('gorzka.presentation.classic.trait.5')}
                        </Markdown>
                        <Markdown className={`${trait} six`}>
                            {t('gorzka.presentation.classic.trait.6')}
                        </Markdown>
                    </div>
                )}
            </div>
            {theme === 'mint' && (
                <StaticImage src={leafUrl} alt={t('gorzka.presentation.alt')} className={leaf} />
            )}
        </section>
    );
};

export default GorzkaPresentation;
