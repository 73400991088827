import React, { useEffect, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    startTheme,
    endTheme,
    sectionTitle,
    icon,
    wrapper,
    imagesContainer,
    image,
    splash,
    bottle,
    bottleLeft,
    bottleRight,
    animationOne,
    animationTwo,
    box,
    classic,
    mint,
    title,
    description,
    button,
} from './gorzka-preview.module.scss';

import IconRight from '../../assets/images/svg/chevron-right.svg';
import CrownIcon from '../../assets/images/svg/gorzka-theme.svg';

import useMediaQuery from '../../hooks/use-media-query';
import { breakpoints } from '../../config/breakpoints';

import Markdown from '../hoc/markdown';

const previewImage = '../../assets/images/soplica-gorzka/preview.png';
const previewImageNoSplash = '../../assets/images/soplica-gorzka/preview-no-splash.png';
const previewSplash = '../../assets/images/soplica-gorzka/preview-splash.png';
const classicBottleImage = '../../assets/images/soplica-gorzka/preview-bottle-classic.png';
const mintBottleImage = '../../assets/images/soplica-gorzka/preview-bottle-mint.png';

interface IGorzkaPreviewProps {
    className?: string;
    theme: 'start' | 'end';
}

const GorzkaPreview: React.FC<IGorzkaPreviewProps> = ({ className = '', theme }) => {
    const isMobile = useMediaQuery(breakpoints.phone);
    const { t } = useI18next();
    const bottlesRef = useRef<HTMLDivElement>(null);
    const titlesRef = useRef<HTMLDivElement>(null);
    const [bottlesAnimation, setBottlesAnimation] = useState(0);
    const [titlesAnimation, setTitlesAnimation] = useState(0);

    useEffect(() => {
        const bottlesOffsetTop =
            (bottlesRef && bottlesRef.current && bottlesRef.current.getBoundingClientRect().top) ||
            0;
        const bottlesHeight =
            (bottlesRef && bottlesRef.current && bottlesRef.current.clientHeight) || 0;
        const titlesOffsetTop =
            (titlesRef && titlesRef.current && titlesRef.current.getBoundingClientRect().top) || 0;
        const titlesHeight =
            (titlesRef && titlesRef.current && titlesRef.current.clientHeight) || 0;

        if (theme === 'start') {
            window.addEventListener('scroll', () => {
                const bottomScreenY = window.scrollY + window.innerHeight;
                const titlesScreenY =
                    (titlesRef &&
                        titlesRef.current &&
                        titlesRef.current.getBoundingClientRect().y) ||
                    0;
                const showFirstBottleAnimation =
                    bottlesOffsetTop + bottlesHeight / 3 < bottomScreenY;
                const showSecondBottleAnimation =
                    titlesOffsetTop + titlesHeight / 2 < bottomScreenY;
                const showFirstTitlesAnimation = titlesOffsetTop + 50 < bottomScreenY;

                const showSecondTitlesAnimation =
                    titlesScreenY + titlesHeight < window.innerHeight / 2;

                if (showSecondBottleAnimation) {
                    setBottlesAnimation(2);
                } else if (showFirstBottleAnimation) {
                    setBottlesAnimation(1);
                } else {
                    setBottlesAnimation(0);
                }

                if (showSecondTitlesAnimation) {
                    setTitlesAnimation(2);
                } else if (showFirstTitlesAnimation) {
                    setTitlesAnimation(1);
                } else {
                    setTitlesAnimation(0);
                }
            });
        } else {
            window.addEventListener('scroll', () => {
                const bottlesY =
                    (bottlesRef &&
                        bottlesRef.current &&
                        bottlesRef.current.getBoundingClientRect().y) ||
                    0;

                const showFirstBottleAnimation = bottlesY + 100 < window.innerHeight;
                const showSecondBottleAnimation = bottlesY + 100 < 0;

                if (showSecondBottleAnimation) {
                    setBottlesAnimation(2);
                } else if (showFirstBottleAnimation) {
                    setBottlesAnimation(1);
                } else {
                    setBottlesAnimation(0);
                }
            });
        }
    }, [bottlesRef, titlesRef]);

    return (
        <section className={`${className} ${container}`}>
            {theme === 'end' && (
                <>
                    <CrownIcon className={icon} />
                    <h2 className={sectionTitle}>{t('gorzka.preview.title')}</h2>
                </>
            )}
            <div
                className={`
                ${imagesContainer} 
                ${theme === 'start' ? startTheme : endTheme}
                ${bottlesAnimation === 1 ? animationOne : ''} 
                ${bottlesAnimation === 2 ? animationTwo : ''}
                `}
                ref={bottlesRef}
            >
                {theme === 'end' ? (
                    <>
                        <StaticImage className={image} src={previewImageNoSplash} alt={''} />
                        <StaticImage className={splash} src={previewSplash} alt={''} />
                    </>
                ) : (
                    <StaticImage className={image} src={previewImage} alt={''} />
                )}

                <StaticImage
                    className={`${bottle} ${bottleLeft}`}
                    src={classicBottleImage}
                    alt="Soplica gorzka"
                />
                <StaticImage
                    className={`${bottle} ${bottleRight}`}
                    src={mintBottleImage}
                    alt="Soplica gorzka miętowa"
                />
            </div>
            {theme === 'start' && (
                <div
                    className={`
                    ${wrapper} 
                    ${titlesAnimation === 1 ? animationOne : ''} 
                    ${titlesAnimation === 2 ? animationTwo : ''}
                    `}
                    ref={titlesRef}
                >
                    <div className={`${box} ${classic}`}>
                        <Markdown className={title}>{t('gorzka.preview.classic.title')}</Markdown>
                        <p className={description}>{t('gorzka.preview.classic.description')}</p>
                        <a className={button} href="#classic">
                            {isMobile
                                ? t('gorzka.preview.classic.button.mobile')
                                : t('gorzka.preview.classic.button')}{' '}
                            <IconRight />
                        </a>
                    </div>
                    <div className={`${box} ${mint}`}>
                        <Markdown className={title}>{t('gorzka.preview.mint.title')}</Markdown>
                        <p className={description}>{t('gorzka.preview.mint.description')}</p>
                        <a className={button} href="#mint">
                            {isMobile
                                ? t('gorzka.preview.mint.button.mobile')
                                : t('gorzka.preview.mint.button')}{' '}
                            <IconRight />
                        </a>
                    </div>
                </div>
            )}
        </section>
    );
};

export default GorzkaPreview;
