import React, { useEffect, useRef, useState } from 'react';
import { Navigation, Pagination, Autoplay, Swiper as SwiperCore } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    sliderGrid,
    sliderFlex,
    sliderBox,
    listBox,
    sliderLoaded,
    buttonPrev,
    buttonNext,
    notVisible,
    mobileHidden,
    hidden,
} from './slider.module.scss';

import ArrowButton from '../atoms/arrow-button';
import ProgressBarElement from '../atoms/progress-bar';

interface ISliderProps {
    className?: string;
    noSliderItemClass?: string;
    activeSlide?: number;
    ButtonComponent?: React.ElementType;
    isSliderFlexbox?: boolean;
    showNav?: 'full' | 'desktop' | 'none';
    forceArrowSpace?: boolean;
    breakpoints?: { [width: number]: { slidesPerView: number } };
    loop?: boolean;
    sliderProps?: Record<string, unknown>;
    paginate?: boolean;
    hideButtons?: boolean;
    arrow?: 'chevron' | 'arrow';
    ProgressBar?: React.ElementType;
}

SwiperCore.use([Autoplay, Navigation, Pagination]);

const defaultBreakpoints: { [width: number]: { slidesPerView: number } } = {
    0: { slidesPerView: 1 },
    700: { slidesPerView: 2 },
    1300: { slidesPerView: 3 },
};

const Slider: React.FC<ISliderProps> = ({
    className = '',
    noSliderItemClass = '',
    activeSlide = 0,
    children,
    ButtonComponent = ArrowButton,
    showNav = 'full',
    forceArrowSpace = false,
    breakpoints = defaultBreakpoints,
    loop = false,
    sliderProps,
    isSliderFlexbox,
    paginate,
    hideButtons,
    arrow,
    ProgressBar = ProgressBarElement,
}) => {
    const { t } = useI18next();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [swiper, setSwiper] = useState<SwiperCore | undefined>();
    const [showAsSlider, setShowAsSlider] = useState<boolean>(false);
    const prevRef = useRef<HTMLButtonElement>(null);
    const nextRef = useRef<HTMLButtonElement>(null);
    const progressRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const handleResize = () => {
            setShowAsSlider(checkIfShowSlider(children, breakpoints, loop));
        };

        setTimeout(() => {
            handleResize();
        }, 0);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!swiper) return;
        if (!loop) swiper.slideTo(activeSlide);
        else swiper.slideToLoop(activeSlide);
    }, [swiper, activeSlide]);

    return (
        <div className={className}>
            <div className={`${sliderGrid} ${isSliderFlexbox ? sliderFlex : ''}`}>
                {showAsSlider ? (
                    <div className={`${sliderBox} ${loaded ? sliderLoaded : ''} slider-box`}>
                        <Swiper
                            onInit={() => setLoaded(true)}
                            onSwiper={(swiper) => setSwiper(swiper)}
                            slidesPerView={1}
                            spaceBetween={15}
                            breakpoints={breakpoints}
                            navigation={{
                                prevEl: prevRef.current,
                                nextEl: nextRef.current,
                            }}
                            {...sliderProps}
                            modules={[Autoplay, Navigation, Pagination]}
                            pagination={
                                paginate
                                    ? {
                                          el: progressRef.current,
                                          clickable: true,
                                      }
                                    : false
                            }
                        >
                            {Array.isArray(children) &&
                                children.map((child, index) => {
                                    if (!child) return null;

                                    return (
                                        <SwiperSlide key={`slide-${index}`}>{child}</SwiperSlide>
                                    );
                                })}
                            <ProgressBar ref={progressRef} />
                        </Swiper>
                    </div>
                ) : (
                    <div
                        className={`${listBox} list-box`}
                        style={{
                            gridTemplateColumns: `repeat(${getChildrenLength(
                                children
                            )}, calc(100% / ${getChildrenLength(children)} - 10px * ${
                                getChildrenLength(children) - 1
                            } / ${getChildrenLength(children)}))`,
                        }}
                    >
                        {Array.isArray(children) &&
                            children.map((child, index) => {
                                if (!child) return null;

                                return (
                                    <div className={noSliderItemClass} key={`list-item-${index}`}>
                                        {child}
                                    </div>
                                );
                            })}
                    </div>
                )}
                {!hideButtons && (
                    <>
                        <ButtonComponent
                            ref={prevRef}
                            className={`${buttonPrev} ${getNavClass(showNav)} ${
                                !showAsSlider ? (forceArrowSpace ? notVisible : hidden) : ''
                            }`}
                            ariaLabel={t('button.prev.slide')}
                            rotate={-180}
                            arrow={arrow}
                        />
                        <ButtonComponent
                            ref={nextRef}
                            className={`${buttonNext} ${getNavClass(showNav)} ${
                                !showAsSlider ? (forceArrowSpace ? notVisible : hidden) : ''
                            }`}
                            ariaLabel={t('button.next.slide')}
                            arrow={arrow}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

function getNavClass(mode: string) {
    if (mode === 'none') {
        return hidden;
    }

    if (mode === 'desktop') {
        return mobileHidden;
    }

    return '';
}

function checkIfShowSlider(
    children: React.ReactNode,
    breakpoints: { [width: number]: { slidesPerView: number } },
    loop: boolean
): boolean {
    const slideCount = getChildrenLength(children);
    const slidesInView = getSlidesInView(breakpoints);
    if (slidesInView) {
        return slideCount > slidesInView || loop;
    }
    return true;
}

function getSlidesInView(breakpoints: {
    [width: number]: { slidesPerView: number };
}): number | undefined {
    if (typeof window === 'undefined') return;

    const activeBreakpoint = Object.keys(breakpoints)
        .map((key) => Number(key))
        .sort((a, b) => b - a)
        .find((key) => key <= window.innerWidth);

    if (activeBreakpoint || activeBreakpoint === 0) {
        return breakpoints[activeBreakpoint].slidesPerView;
    }
}

function getChildrenLength(children: React.ReactNode) {
    return (Array.isArray(children) && children.length) || 0;
}

export default Slider;
