import React from 'react';

import { button, chevron } from './arrow-button.module.scss';
import ArrowIcon from '../../assets/images/svg/arrow.svg';
import ChevronIcon from '../../assets/images/svg/chevron-right.svg';

interface IArrowButtonProps {
    className?: string;
    rotate?: number;
    onClick?(): any;
    ariaLabel?: string;
    arrow?: 'chevron' | 'arrow';
}

const ArrowButton = React.forwardRef<HTMLButtonElement, IArrowButtonProps>((props, ref) => {
    const {
        className = '',
        rotate = 0,
        onClick = () => {},
        ariaLabel = '',
        arrow = 'arrow',
    } = props;

    return (
        <button
            ref={ref}
            className={`${button} ${className}`}
            aria-label={ariaLabel}
            onClick={onClick}
        >
            {arrow === 'arrow' && <ArrowIcon style={{ transform: `rotate(${rotate}deg)` }} />}
            {arrow === 'chevron' && (
                <ChevronIcon className={chevron} style={{ transform: `rotate(${rotate}deg)` }} />
            )}
        </button>
    );
});

export default ArrowButton;
