import React, { useEffect, useRef, useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    section,
    container,
    content,
    icon,
    title,
    description,
    slider,
    card,
    videoRatio,
    videoPlayer,
    firstAnimation,
    secondAnimation,
} from './gorzka-drinks.module.scss';

import CrownIcon from '../../assets/images/svg/gorzka-theme.svg';

import Slider from '../hoc/slider';
import GorzkaCard, { IGorzkaCard } from '../molecules/gorzka-card';
import VideoPlayer from '../atoms/video-player';

interface IGorzkaDrinksProps {
    drinks: IGorzkaCard[];
    className?: string;
}

const GorzkaDrinks: React.FC<IGorzkaDrinksProps> = ({ className = '', drinks }) => {
    const { t } = useI18next();
    const sectionRef = useRef<HTMLElement>(null);
    const [videoAnimation, setVideoAnimation] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const sectionOffsetTop =
                (sectionRef &&
                    sectionRef.current &&
                    sectionRef.current.getBoundingClientRect().top) ||
                0;
            const sectionHeight =
                (sectionRef && sectionRef.current && sectionRef.current.clientHeight) || 0;
            const showFirstAnimation = sectionOffsetTop + sectionHeight / 4 < window.innerHeight;
            const showSecondAnimation = sectionOffsetTop * -1 > window.innerHeight / 2;

            if (showSecondAnimation) {
                setVideoAnimation(2);
            } else if (showFirstAnimation) {
                setVideoAnimation(1);
            } else {
                setVideoAnimation(0);
            }
        });
    }, [sectionRef]);

    return (
        <section
            className={`
            ${section} ${className} 
            ${videoAnimation === 1 ? firstAnimation : ''}
            ${videoAnimation === 2 ? secondAnimation : ''}
            `}
            ref={sectionRef}
        >
            <div className={container}>
                <VideoPlayer
                    url={`/videos/drinks_classic.mp4`}
                    ratioClassName={videoRatio}
                    className={videoPlayer}
                    type={'video/mp4'}
                    playing={true}
                    controls={false}
                    loop={true}
                    muted
                />
                <div className={content}>
                    <CrownIcon className={icon} />
                    <h2 className={title}>{t('gorzka.drinks.classic.title')}</h2>
                    <p className={description}>{t('gorzka.drinks.description')}</p>
                    <Slider
                        className={slider}
                        noSliderItemClass={card}
                        sliderProps={{
                            slidesPerView: 3,
                            breakpoints: {
                                0: { slidesPerView: 'auto' },
                                700: { slidesPerView: 2 },
                                1150: { slidesPerView: 3 },
                            },
                        }}
                    >
                        {drinks.map((drink, i) => {
                            return <GorzkaCard card={drink} key={`drink-${i}`} />;
                        })}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default GorzkaDrinks;
