import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { TFunction } from 'react-i18next';

import { container } from './soplica-gorzka.module.scss';

import { IGorzkaCard } from '../components/molecules/gorzka-card';
import {IPage} from "../models/page.model";

import GorzkaHeader from '../components/organisms/gorzka-header';
import GorzkaHero from '../components/organisms/gorzka-hero';
import GorzkaPreview from '../components/organisms/gorzka-preview';
import GorzkaPresentation from '../components/organisms/gorzka-presentation';
import GorzkaIngredients from '../components/organisms/gorzka-ingredients';
import GorzkaBottleSpin from '../components/organisms/gorzka-bottle-spin';
import GorzkaDrinks from '../components/organisms/gorzka-drinks';
import GorzkaFooter from '../components/organisms/gorzka-footer';
import SEO from "../components/seo";

interface ISoplicaGorzkaProps {
    readonly data: {
        page: IPage | null;
    };
}

const SoplicaGorzka: React.FC<ISoplicaGorzkaProps> = ({ data }) => {
    const { page } = data;
    const { t } = useI18next();
    const drinksList = getDrinksList(t);

    return (
        <>
            <SEO meta={page?.meta} />
            <div className={container}>
                <GorzkaHeader />
                <GorzkaHero />
                <GorzkaPreview theme={'start'} />
                <GorzkaPresentation theme={'classic'} />
                <GorzkaIngredients theme={'classic'} />
                <GorzkaPresentation theme={'mint'} />
                <GorzkaIngredients theme={'mint'} />
                <GorzkaBottleSpin theme={'main'} />
                <GorzkaDrinks drinks={drinksList} />
                <GorzkaPreview theme={'end'} />
                <GorzkaFooter theme={'main'} />
            </div>
        </>
    );
};

function getDrinksList(t: TFunction<string | string[], undefined>): IGorzkaCard[] {
    return [
        {
            type: 'classic',
            name: t('gorzka.drink.1.title'),
            img: 'tango',
            ingredients: [
                t('gorzka.drink.ingredient.classic'),
                t('gorzka.drink.ingredient.grapefruit.juice'),
                t('gorzka.drink.ingredient.ice'),
                t('gorzka.drink.ingredient.garnish.grapefruit'),
            ],
        },
        {
            type: 'classic',
            img: 'grazka',
            name: t('gorzka.drink.2.title'),
            ingredients: [
                t('gorzka.drink.ingredient.classic'),
                t('gorzka.drink.ingredient.tonic'),
                t('gorzka.drink.ingredient.ice'),
                t('gorzka.drink.ingredient.garnish.cucumber'),
            ],
        },
        {
            type: 'classic',
            name: t('gorzka.drink.3.title'),
            img: 'discowrotki',
            ingredients: [
                t('gorzka.drink.ingredient.classic'),
                t('gorzka.drink.ingredient.gingerbeer'),
                t('gorzka.drink.ingredient.ice'),
                t('gorzka.drink.ingredient.garnish.mint'),
            ],
        },
        {
            type: 'mint',
            name: t('gorzka.drink.4.title'),
            img: 'wyprawka',
            ingredients: [
                t('gorzka.drink.ingredient.mint'),
                t('gorzka.drink.ingredient.apple.juice'),
                t('gorzka.drink.ingredient.ice'),
                t('gorzka.drink.ingredient.garnish.apple'),
            ],
        },
        {
            type: 'mint',
            name: t('gorzka.drink.5.title'),
            img: 'mohito',
            ingredients: [
                t('gorzka.drink.ingredient.mint'),
                t('gorzka.drink.ingredient.lemonade'),
                t('gorzka.drink.ingredient.ice'),
                t('gorzka.drink.ingredient.garnish.lime'),
            ],
        },
    ];
}

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default SoplicaGorzka;
